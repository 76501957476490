@import './../../../styles/variables';
@import './../../../styles/color';

.portfolio-navbar {
    white-space: nowrap;
    &.bg-light {
        background: $white !important;
        &.scrolled {
            background: $bright-gray !important;
        }
    }
    &.bg-dark {
        background: $bg-dark !important;
        &.scrolled {
            background: $gray !important;
        }
    }
    .container {
        .navbar-brand {
            color: $primary-teal;
            margin-bottom: 0;
        }
        .navbar-collapse.collapse {
            div.navbar-nav {
                .nav-link {
                    font-weight: $bold;
                    cursor: pointer;
                    &.active {
                        color: $primary-teal;
                    }
                    &:hover {
                        color: $chinese-silver;
                    }
                }
            }
        }
    }
    &.navbar-dark {
        .nav-link {
            color: $white;
            &:focus {
                color: $white;
            }
        }
    }
}
