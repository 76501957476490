@import './../../../styles/color';

.SocialLinks {
    min-width: 15rem;
    svg {
        &:hover {
            color: $primary-teal;
            cursor: pointer;
        }
    }
}
