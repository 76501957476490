.Footer {
    padding: 1rem;
    margin-top: -1rem;
    .footer-text {
        margin-right: 1rem;
        margin-top: 1rem
    }
    .social {
        margin-top: 1rem;
    }
}
