$primary-teal: #0bceaf;
$primary-teal-darker: #00B596;

// light
$bg-light: #fff;

// dark
$bg-dark: #343a40;
$gray: #6c757d;
$gunmetal: #2e3047;
$outer-space: #43455c;
$arsenic: #3c3f58;
$rhythym: #707793;
$jaguar: #100e17;

// grayscale
$white: #fff;
$bright-gray: #eee;
$chinese-silver: #ccc;
$spanish-gray: #999;
$granite-gray: #666;
$dark-charcoal: #333;
$black: #000;

// text
$light-theme-text: #212529;
$dark-theme-text: $white;

// colors
$red: #dc3545;
