.ScrollToTop {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 8px;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.5;
        }
    }
}
