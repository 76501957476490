@import './../../../styles/variables';
@import './../../../styles//color';

.ThemeToggle {
    height: 100%;
    padding: 7px;
    font-weight: $bold;
    .theme-toggle-container {
        .sun {
            margin-right: 0.5rem;
        }
        .light-theme {
            margin-right: 0.5rem;
            &.light-theme-text {
                color: rgba(0, 0, 0, 0.5);
            }
        }
        .Switch {
            label {
                &[for='theme-switch-dark'] {
                    color: $white;
                }
                &[for='theme-switch-light'] {
                    color: rgba(0, 0, 0, 0.5);
                }
            }
        }
        .moon {
            margin-left: 0.5rem;
        }
    }
}
