@import './styles/color';

// text
.light-theme-text {
    color: $light-theme-text;
}

.dark-theme-text {
    color: $dark-theme-text;
}

// backgrounds
.dark-theme-bg {
    background-color: $bg-dark;
}

.light-theme-bg {
    background-color: $bg-light;
}

// links
.dark-theme-link {
    color: #6ba3ff;
}

.light-theme-link {
    color: #0645ad;
}

// Skeleton blocks
.skeleton-block {
    background-color: $spanish-gray;
    position: relative;
    overflow: hidden;
    &::before {
        content: '';
        display: block;
        position: absolute;
        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;;
        background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
        animation: load 2s infinite;
    }
}

html,
body {
    width: 100%;
    margin: 0px;
    padding: 0px;
    padding-right: 0 !important;
    overflow-x: hidden;
}

.dark-theme-bg,
.light-theme-bg,
.dark-theme-text,
.light-theme-text,
.dark-theme-link,
.light-theme-link,
.custom-control-label,
.nav-link {
    transition: background-color 250ms linear, color 250ms linear;
}
