$bold: 600;

// screen sizes
$small: 576px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;

// sidenav
$sidenav-height: 55px;
$footer-height: 51px;
